<template>
  <div> 
    <div>
      <!-- Default Segment -->
      <ion-segment scrollable value="metro">
        <ion-segment-button value="metro" @click="updateSceneType('metro')">
          <ion-label>Scene</ion-label>
        </ion-segment-button>
        <ion-segment-button value="state" @click="updateSceneType('state')">
          <ion-label>State</ion-label>
        </ion-segment-button>
        <ion-segment-button value="region" @click="updateSceneType('region')">
          <ion-label>Region</ion-label>
        </ion-segment-button>
        <ion-segment-button value="country" @click="updateSceneType('country')">
          <ion-label>Country</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <div class="padding-left-md padding-top-sm padding-right-md">
      <ion-input v-model="keyword" type="text" placeholder="Enter scene, state or region"></ion-input>
    </div>
    <!-- List of Text Items -->
    <div v-if="matchedItems">
      <ion-list v-if="matchedItems.length > 0">
        <ion-item v-for="scene in matchedItems" :key="scene" detail @click="updateRoute('/scene/'+scene.slug)">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-avatar v-if="scene.type == 'metro'" slot="start">
              <img :src="scene.image" />
            </ion-avatar>          
          <ion-label>{{scene.name}}<span v-if="scene.type == 'metro'">, {{scene.state}}</span></ion-label>
        </ion-item>
      </ion-list>
      <div v-else class="ion-padding">
        There are no results
      </div>
    </div>
  </div>
</template>

<script>
import { IonSegment, IonSegmentButton, loadingController, IonItem, IonList, IonLabel, IonAvatar, IonInput } from '@ionic/vue';
import { defineComponent, computed, onMounted, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SceneSearch', 
  components: {
    IonSegment, IonSegmentButton, IonItem, IonList, IonLabel, IonAvatar, IonInput
  },
  setup() {
    
    const store = useStore();
    const router = useRouter();
    const authUser = computed(() => store.state.authUser);
    const authToken = computed(() => store.state.authToken);
    const sceneType = ref('metro')
    const scenes = ref([]);
    const matchedItems = ref([]);
    const keyword = ref(null);
    const timeout = { default: 3000 }

    onMounted(() => {
      getScenes()
    })

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() { loading.dismiss() }, timeout);
    }

    function getScenes() {
      presentLoading()
      apiClient.get('/api/get/scenes', {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          scenes.value = response.data
        }).catch(error => {
          console.log(error)  
        });      
    }

    function updateSceneType(val) {
      sceneType.value = val;
    }

    function updateRoute(val) {
      router.push(val)
    }

    function searchScenes() {
      matchedItems.value = []
      for(var i=0;i<scenes.value.length;i++) {
        if(scenes.value[i].type == sceneType.value) {
          // There is a keyword
          if(keyword.value != null && keyword.value != '') {
            //matchedItems.value.push(scenes.value[i])
            if(scenes.value[i].name.toLowerCase().includes(keyword.value.toLowerCase()) || scenes.value[i].slug.toLowerCase().includes(keyword.value.toLowerCase()) || scenes.value[i].state.toLowerCase().includes(keyword.value.toLowerCase()) || scenes.value[i].state_full.toLowerCase().includes(keyword.value.toLowerCase()) || scenes.value[i].region.toLowerCase().includes(keyword.value.toLowerCase())) {
              matchedItems.value.push(scenes.value[i])
            }
          }
          // default
          else {
            matchedItems.value.push(scenes.value[i])
          } 
        }
      }
    }

    function defaultSceneList() {
      matchedItems.value = []
      for(var i=0;i<scenes.value.length;i++) {
        if(scenes.value[i].type == 'metro') {
          matchedItems.value.push(scenes.value[i])
        }
      }
    }

    return {
      authUser, authToken, sceneType, getScenes, scenes, presentLoading, updateSceneType, router, updateRoute, matchedItems, keyword, searchScenes, defaultSceneList
    }
  }, 
  watch: {
    scenes: {
      handler() {
        this.defaultSceneList()
      },
      deep:true
    },
    keyword: function() {
      this.searchScenes()
    },
    sceneType: function() {
      this.searchScenes()
    }
  }
});
</script>

<style scoped>

  ion-segment {
    border-radius:0px!important;
  }


</style>